// actions & mutations
export const LOAD_TYPES = "loadTypes";
export const RECACHE_TYPES = "recacheTypes";
export const LOAD_TYPES_BY_GROUP = "loadTypesOfGroup";
export const LOAD_TYPES_COMPLETED = "loadTypesCompleted";
export const LOAD_TYPES_BY_GROUP_COMPLETED = "loadTypesOfGroupCompleted";
export const SAVE_TYPE = "saveType";

// getters
export const GET_TYPE_GROUPS = "getTypeGroups";
export const GET_TYPE_GROUP = "getTypeGroup";
export const GET_TYPES = "getTypes";
export const GET_TYPES_BY_GROUP = "getTypesByGroup";
export const GET_TYPE_BY_GROUP_AND_ID = "getTypeByGroupAndId";
export const GET_TYPE_BY_GROUP_AND_NAME = "getTypeByGroupAndName";
export const GET_TYPES_BY_GROUP_AND_IDS = "getTypesByGroupAndIds";
export const GET_TYPE_NAME = "getTypeName"
export const GET_TYPE_NAME_EN = "getTypeNameEn"

